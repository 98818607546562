import { Controller } from "stimulus"
import DriveFilesApi from "@api/DriveFilesApi";
import { type ExposeStimulus, StimulusControllerBase } from "./base_controller";

export interface DocumentController extends ExposeStimulus<typeof DocumentController> {}
export class DocumentController extends StimulusControllerBase {

  static values = {
    modelId: String,
  }

  async sendRequestAccess()  {
    try {
      const data = await DriveFilesApi.requestAccessToDocument<{
        noticeMsg: string
      }>({ id: this.modelIdValue })

      avv_dialog({snackMessage: data.noticeMsg, snackStyle: "notice"})
    } catch (error) {
      avv_dialog({snackMessage: (error as { message: string }).message, snackStyle: "error"})
    }
  }

  async addUserAsParticipant()  {
    try {
      const response = await DriveFilesApi.addCurrentUserAsParticipant<{url:string}>({ id: this.modelIdValue })
      const url = response.url
      avv_toast({message: localizeText('notices.added_as_participant')})
      if(url) window.location.href = url
      else window.location.reload()
    } catch (error) {
      avv_dialog({snackMessage: (error as { message: string }).message, snackStyle: "error"})
    }
  }
}

export default DocumentController
